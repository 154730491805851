
.monitor-button {
  text-align: center;
  white-space: nowrap;
  font-size: 1.125em;
  font-style: normal;
  font-weight: $font-weight;
  letter-spacing: -0.02em;
  background: $primary-color;
  border-radius: 50px;
  padding: 0 28px;
  display: flex;
  align-items: center;
  border: none;
  height: 50px;
  color: #fff;
  cursor: pointer;
  margin: 0 10px 10px 0;

  &.blue {
    background: $primary-color;
  }

  &.white {
    background: white;
    color: $primary-color;
    border: 1px solid #888;
  }

  &:disabled {
    cursor: unset;
    background: #ccc;
    border: 1px solid #ccc;
    color: #fff;

    span {
      color: #fff;
    }
  }
}

.index {
  font-family: $font-family;
  display: flex;

  .left {
    width: 50vw;
    color: #333;

    .welcome {
      margin-top: 69px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        font-size: 18px;
        line-height: 22px;
        font-weight: $font-weight;
        font-style: normal;
        letter-spacing: -0.03em;
        text-align: left;
        padding-right: 60px;
        padding-left: 120px;
        margin-top: 22px;

        &.bigger {
          margin-top: 0;
          font-size: 24px;
          line-height: 29px;
        }

        &.bold {
          font-weight: bolder;
        }
      }

      .button-container {
        padding-left: 120px;
        padding-bottom: 20px;
        margin-top: 30px;
        display: flex;

        .monitor-controls {
          background-color: #f2f5f7;
          height: unset;
          padding: 0;

          .monitor-button {
            font-size: 1.125em;
            height: 50px;
          }
        }
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (width <= 1200px) {
  .right {
    display: none !important;
    width: 0;
  }

  .left {
    width: 100% !important;
  }
}

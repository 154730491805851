.content-wrapper {
  font-family: $font-family;

  a {
    text-decoration: none;
  }

  width: 100%;
  background: #f2f5f7;
}

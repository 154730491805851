.import-modal-content {
  .input-row {
    display: flex;
    align-items: center;
    padding-top: 20px;

    .monitor-button {
      height: 40px;
      font-size: 16px;
      margin: 0 15px;
    }

    #input-import-monitor {
      flex: 1;
      height: 42px;
      border-radius: 5px;
      border: solid 1px #888;
      padding: 0 0 0 10px;
      margin: 0;
      font-size: 13px;
    }
  }

  .no-monitor-found {
    position: absolute;
    color: $alert-color;
    font-size: 12px;
    margin: 5px;
  }

  .input-instructions {
    font-size: 15px;
  }

  .import-preview {
    margin: 30px 0;
    border-radius: 5px;
    border: solid 1px #ddd;
    padding: 0 15px;

    .monitor-input-container {
      margin-right: 5px;
    }
  }

  .import-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;

    .monitor-button {
      height: 40px;
      font-size: 16px;
      margin: 0 15px;
      position: relative;

      .loading-button {
        position: absolute;
        width: calc(100% - 56px); /* 100% - total horizontal padding on monitor-button */
      }

      &.loading:disabled {
        border: none;
        background: $primary-color;
      }
    }
  }
}

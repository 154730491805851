/* ************************************ Main view layouts ************************************ */

/* ********* (also used in preview if preview properties are not defined) ********* */

$routecode-col-width: var(--routecode-col-width);
$calculated-font-size: var(--font-size);
$tightened-font-size: var(--tightened-font-size);

.grid-row {
  grid-template-columns: 21vh 1fr 25vh;

  &.without-route-column {
    grid-template-columns: auto 25vh;
  }
}

/* ********* 4 rows ********* */
$rows4-1st-col-width: 31vh;

.rows4 .grid-row {
  grid-template-columns: $rows4-1st-col-width 1fr 36vh;
  font-size: calc((#{var(--height)} - 30vh) / 11.5);

  .len1,
  .len2 {
    font-size: calc((#{var(--height)} - 30vh) / 8);
  }

  &.alert {
    font-size: calc((var(--height) - 30vh) / 8);
  }
}

.rows4 .grid-row.with-stop-code {
  grid-template-columns: $rows4-1st-col-width 1fr 20vh 36vh;

  &.without-route-column {
    grid-template-columns: auto 20vh 36vh;
  }
}

/* ********* 8 rows ********* */
$rows8-1st-col-width: 28vh;

.rows8 .grid-row {
  grid-template-columns: $rows8-1st-col-width 1fr 25vh;
  font-size: calc((#{var(--height)} - 30vh) / 13);

  &.without-route-column {
    grid-template-columns: auto 25vh;
  }
}

.rows8 .grid-row.with-stop-code {
  grid-template-columns: $rows8-1st-col-width 1fr 20vh 25vh;

  &.without-route-column {
    grid-template-columns: auto 20vh 25vh;
  }
}

/* ********* 12 rows ********* */
$rows12-1st-col-width: 22vh;

.rows12 .grid-row {
  font-size: calc((#{var(--height)} - 30vh) / 17);
  grid-template-columns: $rows12-1st-col-width 1fr 20vh;

  &.without-route-column {
    grid-template-columns: auto 20vh;
  }
}

.rows12 .grid-row.with-stop-code {
  grid-template-columns: $rows12-1st-col-width 1fr 15vh 20vh;

  &.without-route-column {
    grid-template-columns: auto 15vh 20vh;
  }
}

/* ****************** 2 columns ****************** */

/* ********* 4 rows ********* */
$rows4-2col-1st-col-width: 31vh;

.rows4.two-cols .grid-row {
  grid-template-columns: $rows4-2col-1st-col-width 1fr 19vh;

  &.without-route-column {
    grid-template-columns: auto 19vh;
  }
}

.rows4.two-cols .grid-row.with-stop-code {
  grid-template-columns: $rows4-2col-1st-col-width 1fr 17vh 19vh;

  &.without-route-column {
    grid-template-columns: auto 17vh 19vh;
  }
}

/* ********* 8 rows ********* */
$rows8-2col-1st-col-width: 28vh;

.rows8.two-cols .grid-row {
  grid-template-columns: $rows8-2col-1st-col-width 1fr 18vh;
}

.rows8.two-cols .grid-row.with-stop-code {
  grid-template-columns: $rows8-2col-1st-col-width 1fr 17vh 18vh;

  &.without-route-column {
    grid-template-columns: auto 17vh 18vh;
  }
}

/* ********* 12 rows ********* */
$rows12-2col-1st-col-width: 17vh;

.rows12.two-cols .grid-row {
  grid-template-columns: $rows12-2col-1st-col-width 1fr 18vh;
}

.rows12.two-cols .grid-row.with-stop-code {
  grid-template-columns: $rows12-2col-1st-col-width 1fr 13vh 18vh;

  &.without-route-column {
    grid-template-columns: auto 13vh 18vh;
  }
}

/* ****************** Portrait ****************** */
$tightened-1st-column-width: $routecode-col-width;

/* ********* 4 rows ********* */

.rows4.portrait .grid-row {
  grid-template-columns: $tightened-1st-column-width 1fr 22vh;

  &.without-route-column {
    grid-template-columns: auto 22vh;
  }

  .len1,
  .len2 {
    font-size: calc((#{var(--height)} - 30vh) / 12);
  }

  font-size: $calculated-font-size;
}

/* ********* 6 rows ********* */

.rows6.portrait .grid-row {
  font-size: $calculated-font-size;
  grid-template-columns: $tightened-1st-column-width 1fr 15vh;

  &.without-route-column {
    grid-template-columns: auto 15vh;
  }
}

/* ********* 8 rows ********* */
$rows8-portrait-1st-col-width: $routecode-col-width;

.rows8.portrait .grid-row.with-stop-code {
  grid-template-columns: $rows8-portrait-1st-col-width 1fr 12vh 17vh;

  &.without-route-column {
    grid-template-columns: auto 12vh 17vh;
  }

  .grid-col {
    align-self: flex-start;
  }
}

.rows8.portrait .grid-row {
  grid-template-columns: $rows8-portrait-1st-col-width 1fr 17vh;

  &.without-route-column {
    grid-template-columns: auto 17vh;
  }

  font-size: $calculated-font-size;

  .len1,
  .len2 {
    font-size: calc((#{var(--height)} - 30vh) / 12);
  }
}

/* ********* 12 rows ********* */
$rows12-portrait-1st-col-width: $routecode-col-width;

.rows12.portrait .grid-row {
  font-size: $calculated-font-size;
  grid-template-columns: $rows12-portrait-1st-col-width 1fr 17vh;

  &.without-route-column {
    grid-template-columns: auto 17vh;
  }
}

.rows12.portrait .grid-row.with-stop-code {
  grid-template-columns: $rows12-portrait-1st-col-width 1fr 11vh 17vh;

  &.without-route-column {
    grid-template-columns: auto 11vh 17vh;
  }
}

/* ********* 16 rows ********* */
$rows16-portrait-1st-col-width: $routecode-col-width;

.rows16.portrait .grid-row {
  grid-template-columns: $rows16-portrait-1st-col-width 1fr 15vh;

  &.without-route-column {
    grid-template-columns: auto 15vh;
  }

  font-size: $calculated-font-size;
}

.rows16.portrait .grid-row.with-stop-code {
  grid-template-columns: $rows16-portrait-1st-col-width 1fr 10vh 15vh;

  &.without-route-column {
    grid-template-columns: auto 10vh 15vh;
  }
}

/* ********* 24 rows ********* */
$rows24-portrait-1st-col-width: $routecode-col-width;

.rows24.portrait .grid-row {
  grid-template-columns: $rows24-portrait-1st-col-width 1fr 14vh;

  &.without-route-column {
    grid-template-columns: auto 14vh;
  }

  font-size: $calculated-font-size;
}

.rows24.portrait .grid-row.with-stop-code {
  grid-template-columns: $rows24-portrait-1st-col-width 1fr 8vh 14vh;

  &.without-route-column {
    grid-template-columns: auto 8vh 14vh;
  }
}

/* ****************** Tightened ****************** */

/* ********* 4 rows ********* */
.rows4.portrait.tightened .grid-row.with-stop-code {
  grid-template-columns: $tightened-1st-column-width 1fr 13vh 15vh;

  &.without-route-column {
    grid-template-columns: auto 13vh 15vh;
  }
}

/* ********* 6 rows ********* */

.rows6.portrait.tightened .grid-row.with-stop-code {
  grid-template-columns: $tightened-1st-column-width 1fr 13vh 15vh;

  &.without-route-column {
    grid-template-columns: auto 13vh 15vh;
  }
}

/* ********* 12 rows ********* */
.rows12.portrait.tightened .grid-row {
  grid-template-columns: $tightened-1st-column-width 1fr 15vh;
  font-size: $tightened-font-size;
}

.rows12.portrait.tightened .grid-row.with-stop-code {
  grid-template-columns: $tightened-1st-column-width 1fr 13vh 15vh;
}

/* ****************************************** Preview layouts ****************************************** */

.preview {
  /* ********* Preview 4 rows ********* */
  $p-rows4-first-column-width: 120px;

  .rows4 .grid-row {
    grid-template-columns: $p-rows4-first-column-width 1fr 100px;
    font-size: 30px;

    .len1,
    .len2 {
      font-size: 46px;
    }

    &.alert {
      font-size: 30px !important;
    }

    &.without-route-column {
      grid-template-columns: auto 100px;
    }
  }

  .rows4 .grid-row.with-stop-code {
    grid-template-columns: $p-rows4-first-column-width 1fr 70px 100px;

    &.without-route-column {
      grid-template-columns: auto 70px 100px;
    }
  }

  /* ********* Preview 6 rows ********* */

  .rows6.portrait .grid-row.with-stop-code {
    grid-template-columns: 75px 1fr 70px 95px;

    &.without-route-column {
      grid-template-columns: auto 70px 95px;
    }
  }

  /* ********* Preview 8 rows ********* */
  $p-rows8-first-column-width: 100px;

  .rows8 .grid-row {
    grid-template-columns: $p-rows8-first-column-width 1fr 70px;
    font-size: 20px;

    &.without-route-column {
      grid-template-columns: auto 70px;
    }
  }

  .rows8 .grid-row.with-stop-code {
    grid-template-columns: $p-rows8-first-column-width 1fr 70px 70px;

    &.without-route-column {
      grid-template-columns: auto 70px 70px;
    }
  }

  /* ********* Preview 12 rows ********* */
  $p-rows12-first-column-width: 70px;

  .rows12 .grid-row {
    grid-template-columns: $p-rows12-first-column-width 1fr 60px;

    &.without-route-column {
      grid-template-columns: auto 60px;
    }

    font-size: 17px;
  }

  .rows12 .grid-row.with-stop-code {
    grid-template-columns: $p-rows12-first-column-width 1fr 60px 60px;

    &.without-route-column {
      grid-template-columns: auto 60px 60px;
    }
  }

  /* *************************** Preview: 2 columns *************************** */

  /* ********* Preview: 2 columns, 4 rows ********* */
  $p-rows4-first-column-width: 65px;

  .rows4.two-cols .grid-row {
    grid-template-columns: $p-rows4-first-column-width 1fr 60px;

    &.without-route-column {
      grid-template-columns: auto 80px;
    }

    font-size: 22px;
  }

  .rows4.two-cols .grid-row.with-stop-code {
    grid-template-columns: $p-rows4-first-column-width 1fr 60px 60px;

    &.without-route-column {
      grid-template-columns: auto 70px 80px;
    }

    font-size: 22px;
  }

  /* ********* Preview: 2 columns, 8 rows ********* */
  $p-2col-rows8-first-column-width: 80px;

  .rows8.two-cols .grid-row {
    grid-template-columns: $p-2col-rows8-first-column-width 1fr 60px;

    &.without-route-column {
      grid-template-columns: auto 80px;
    }
  }

  .rows8.two-cols .grid-row.with-stop-code {
    grid-template-columns: $p-2col-rows8-first-column-width 1fr 60px 60px;

    &.without-route-column {
      grid-template-columns: auto 60px 80px;
    }
  }

  /* *************************** Preview: Portrait *************************** */

  /* ********* Preview: 8 rows ********* */

  $p-rows8-portrait-1st-column-width: $routecode-col-width;

  .rows8.portrait .grid-row {
    grid-template-columns: $p-rows8-portrait-1st-column-width 1fr 80px;

    &.without-route-column {
      grid-template-columns: auto 80px;
    }

    font-size: $calculated-font-size;

    .len1,
    .len2 {
      font-size: 30px;
    }
  }

  .rows8.portrait .grid-row.with-stop-code {
    grid-template-columns: $p-rows8-portrait-1st-column-width 1fr 80px 80px;

    &.without-route-column {
      grid-template-columns: auto 80px 80px;
    }

    .grid-col {
      align-self: center;
    }
  }

  /* ********* Preview: 12 rows ********* */
  $p-rows12-portrait-1st-column-width: $routecode-col-width;

  .rows12.portrait .grid-row.with-stop-code {
    grid-template-columns: $p-rows12-portrait-1st-column-width 1fr 70px 95px;

    &.without-route-column {
      grid-template-columns: auto 70px 95px;
    }
  }

  .rows12.portrait .grid-row {
    grid-template-columns: $p-rows12-portrait-1st-column-width 1fr 95px;

    &.without-route-column {
      grid-template-columns: auto 95px;
    }

    font-size: $calculated-font-size;

    .len1,
    .len2 {
      font-size: 35px;
    }
  }

  /* ********* Preview: 16 rows ********* */
  $p-rows16-portrait-1st-column-width: $routecode-col-width;

  .rows16.portrait .grid-row {
    grid-template-columns: $p-rows16-portrait-1st-column-width 1fr 80px;

    &.without-route-column {
      grid-template-columns: auto 80px;
    }

    font-size: $calculated-font-size;
  }

  .rows16.portrait .grid-row.with-stop-code {
    grid-template-columns: $p-rows16-portrait-1st-column-width 1fr 80px 80px;

    &.without-route-column {
      grid-template-columns: auto 80px 80px;
    }
  }

  /* ********* Preview: 24  rows ********* */
  $p-rows24-portrait-1st-column-width: $routecode-col-width;

  .rows24.portrait .grid-row.with-stop-code {
    grid-template-columns: $p-rows24-portrait-1st-column-width 1fr 60px 80px;

    &.without-route-column {
      grid-template-columns: auto 60px 80px;
    }
  }

  .rows24.portrait .grid-row {
    grid-template-columns: $p-rows24-portrait-1st-column-width 1fr 80px;

    &.without-route-column {
      grid-template-columns: auto 80px;
    }

    font-size: $calculated-font-size;
  }

  /* ********* Preview: Tightened views ********* */
  $p-tightened-1st-column-width: $routecode-col-width;

  /* ********* Preview: 4 rows ********* */

  .rows4.tightened.portrait .grid-row {
    grid-template-columns: $p-tightened-1st-column-width 1fr 70px;

    &.without-route-column {
      grid-template-columns: auto 95px;
    }

    font-size: $calculated-font-size;

    .len1,
    .len2 {
      font-size: 44px;
    }
  }

  .rows4.portrait.tightened .grid-row.with-stop-code {
    grid-template-columns: $p-tightened-1st-column-width 1fr 70px 95px;

    &.without-route-column {
      grid-template-columns: auto 70px 95px;
    }
  }

  /* ********* Preview: 6 rows ********* */

  .rows6.tightened.portrait .grid-row {
    grid-template-columns: $p-tightened-1st-column-width 1fr 95px;

    &.without-route-column {
      grid-template-columns: auto 95px;
    }

    font-size: $calculated-font-size;

    .len1,
    .len2 {
      font-size: 30px;
    }
  }

  .rows6.portrait.tightened .grid-row.with-stop-code {
    grid-template-columns: $p-tightened-1st-column-width 1fr 70px 95px;

    &.without-route-column {
      grid-template-columns: auto 70px 95px;
    }
  }

  /* ********* Preview: 12 rows ********* */
  .rows12.portrait.tightened .grid-row {
    grid-template-columns: $p-tightened-1st-column-width 1fr 60px;
    font-size: $calculated-font-size;

    .len1,
    .len2 {
      font-size: 16px;
    }
  }

  .rows12.portrait.tightened .grid-row.with-stop-code {
    grid-template-columns: $p-tightened-1st-column-width 1fr 70px 95px;

    &.without-route-column {
      grid-template-columns: auto 70px 95px;
    }
  }
}

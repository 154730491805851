.information-monitor-container {
  margin: 8vh 4vh;

  .alert-header {
    font-size: 5vh;
  }

  .alert-description {
    font-size: 3vh;
    margin-bottom: 7vh;
  }

  .no-alerts-container {
    height: 250px;
    display: flex;
    justify-content: center;
    font-size: 4vh;
    align-items: center;
    text-align: center;
  }

  &.portrait {
    margin: 4vh 2vh;

    .alert-header {
      font-size: 4vh;
    }

    .alert-description {
      font-size: 2vh;
      margin-bottom: 5vh;
    }

    &.preview {
      .no-alerts-container h2 {
        font-size: 41px;
      }
    }
  }

  &.preview {
    margin: 10px;

    .alert-header {
      font-size: 18px;
      margin: 10px 0 5px;
    }

    .alert-description {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
}

.user-monitors-container {
  width: 780px;
  padding: 40px 60px 30px;

  .create-button-container {
    display: flex;
    margin: 20px 10px;
    justify-content: flex-end;

    .monitor-button {
      height: 40px;
      font-size: 16px;
    }
  }
}

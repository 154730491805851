/* stylelint-disable selector-class-pattern */
.preview {
  .ReactModal__Content {
    border: none !important;
    position: absolute;
    width: 700px !important;
    height: 470px !important;
    top: 50% !important;
    left: 50% !important;
    margin-right: -50%;
    margin-bottom: -50%;
    transform: translate(-50%, -50%);
    padding: 0 !important;
    background: #000 !important;
    box-shadow: 0 4px 20px rgb(0 0 0 / 25%) !important;
    border-radius: 5px !important;
    overflow: hidden !important;
    animation: none;

    #preview-monitor {
      position: absolute;
      top: 75px;
      left: 30px;
    }

    #preview-monitor-portrait {
      position: absolute;
      top: 75px;
      left: 0;
    }

    .title-and-close {
      position: relative;
      width: 640px;
      height: 75px;
      top: 29px;
      left: 30px;

      .title {
        position: relative;
        font-family: $font-family;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.02em;
        color: #fff;
        width: 540px;
      }

      .close {
        position: relative;
        left: 615px;
        top: -28.5px;
        background: transparent;
        border: none;
      }
    }

    .carouselContainer {
      overflow: hidden;
      left: 30px;
      width: 640px;
      height: 370px;

      &.portrait {
        left: 30px;
        width: 370px;
        height: 640px;
      }
    }
  }

  &.portrait {
    .ReactModal__Content {
      border: none !important;
      position: absolute;
      width: 470px !important;
      height: 700px !important;
      top: 50% !important;
      left: 50% !important;
      margin-right: -50%;
      margin-bottom: -50%;
      transform: translate(-50%, -50%);
      padding: 0 !important;
      background: #000 !important;
      box-shadow: 0 4px 20px rgb(0 0 0 / 25%) !important;
      border-radius: 5px !important;
      overflow: hidden !important;
      animation: none;

      .title-and-close {
        position: relative;
        width: 405px;
        height: 75px;
        top: 29px;
        left: 30px;

        .title {
          position: relative;
          font-family: $font-family;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.02em;
          color: #fff;
          width: 305px;
        }

        .close {
          position: relative;
          left: 380px;
          top: -28.5px;
        }
      }

      .carouselContainer {
        position: relative;
        overflow: hidden;
        top: 0;
        left: 30px;
        width: 405px;
        height: 590px;
      }
    }
  }
}

.alert-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 50px;
  padding: 0 20px;
  color: $primary-color;
}

.card {
  width: 720px;
  background-color: #fff;
  padding: 0 30px;
  margin-bottom: 10px;
  border-radius: 5px;

  .buttons-container {
    display: flex;
    border-top: 1px solid #ddd;
    height: 80px;
    align-items: center;
    justify-content: flex-end;

    .main-buttons-container {
      display: flex;
      flex: 1;
    }

    .monitor-button {
      height: 40px;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.main-container > * {
  height: 40px;
  display: flex;
  align-items: center;
}

.main-container {
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;

  .monitor-name {
    flex: 1;
  }

  .layout-img {
    margin: 0 15px 0 0;
  }

  .delete-button-container {
    margin-left: 9px;
    border-left: 1px solid #ddd;
    padding-left: 8px;

    .delete-icon {
      cursor: pointer;
      width: 40px;
      text-align: center;
      justify-content: center;
    }
  }

  .monitor-button {
    font-size: 16px;
    border-radius: 40px;
    margin-bottom: 0;
  }
}

.cards {
  .card-item:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }

  .card-item {
    .card-container {
      display: flex;
      padding: 19px 0 16px;

      svg {
        padding-right: 23px;
        height: 36px !important;
        width: 64px !important;
      }

      .data {
        ul {
          margin-block: 0;
        }

        .card-title {
          position: relative;
          top: -5px;
          font-style: normal;
          font-weight: $font-weight-bigger;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: -0.03em;
          color: #333;

          &:not(:first-child) {
            margin-top: 15px;
          }
        }

        .stop-list {
          li {
            display: flex;
            align-items: center;

            svg {
              height: 16px !important;
              width: 16px !important;
              padding: 0 10px 0 3px;
            }
          }

          font-style: normal;
          font-weight: $font-weight;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.03em;
          color: #333;
        }
      }
    }
  }
}

.monitor-modal-content {
  .message {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 45px;
  }

  .monitor-modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .monitor-button {
      width: 100%;
      justify-content: center;

      &:disabled {
        border: none;
        background: $primary-color;
      }
    }
  }
}

.dd-select {
  width: 100%;
  height: 100%;

  &withWidth { /* stylelint-disable-line */
    min-width: 115px;
  }

  &.withWidth { /* stylelint-disable-line */
    min-width: 150px;

    &.duration {
      min-width: 110px;
      margin-left: 20px;
    }
  }
/* stylelint-disable selector-class-pattern */
  &.dd--is-disabled {
    .dd__indicators {
      svg {
        fill: #ccc !important;
      }
    }
  }

  .dd__placeholder,
  .dd__value-container,
  .dd__single-value {
    color: #888;

    span {
      position: relative;
      top: -14px;
      margin-left: 10px;
      color: #888;
    }
  }

  .dd__group-heading {
    text-align: center;
    font-weight: $font-weight-bigger;
  }

  .dd__option {
    color: #888;

    &--is-selected {
      color: #fff;
    }

    span {
      position: relative;
      top: -14px;
      margin-left: 10px;
    }
  }

  .dd__indicators {
    padding-right: 10px;
  }

  .dd__control {
    height: 44px;

    &--menu-is-open {
      outline: none;
      border-style: none;

      .dd__indicators {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.stop-row-container {
  font-family: $font-family;
  display: flex;
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 40px;
  margin: 10px 0;
  padding: 10px 0;

  &.placeholder {
    margin: 8px 0 0;
    padding: 0;
    border: none;
    position: relative;
    left: -10px;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #666;
  }

  .icon {
    text-align: center;
  }

  .stop-row-stop {
    padding-top: 4px;
    width: 60px;
    text-align: center;
  }

  .stop-bottom-row {
    color: #666;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.03em;
    display: flex;

    .address {
      padding-right: 5px;
    }

    .hidden-choices {
      flex: 1;
      text-align: right;

      svg {
        position: absolute;
        top: 30px;
        right: 67px;
      }
    }
  }

  .stop-row-main {
    flex: 1;
    padding-right: 15px;
    padding-top: 2px;

    .stop-upper-row {
      font-weight: $font-weight-bigger;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.03em;
      margin-bottom: 2px;
      display: flex;
    }
  }

  .stop-row-settings {
    font-weight: var(--font-weight-bigger);
    display: flex;

    .changed-settings {
      display: flex;
      align-items: center;
      color: #666;
      font-size: 13px;
    }

    .settings {
      margin: 0 15px;
      display: flex;
      align-self: center;
      cursor: pointer;
    }
  }

  .stop-row-delete {
    cursor: pointer;
    border-left: 1px solid #ddd;
    width: 50px;
    padding-top: 8px;
  }

  .stop-row-move {
    cursor: pointer;
    border-left: 1px solid #ddd;
    width: 40px;
    list-style-type: none;
  }

  .placeholder-no-stops {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    padding-left: 10px;
  }
}

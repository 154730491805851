
.check-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px;

  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 0;
    overflow: hidden;
    white-space: nowrap;
  }
}

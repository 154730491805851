.navigation-container {
  padding-bottom: 70px;
}

.main-banner {
  background: $primary-color;
  min-width: 100%;
  z-index: 2;
  min-height: 70px !important;
  display: flex;

  .title-logo {
    display: flex;
    flex: 1;
    width: 200px;
    z-index: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;

    .vaasa {
      display: flex;
      width: 115px;
    }

    .linkki {
      width: 60px;
    }

    .oulu {
      width: 100px;
    }

    #logo {
      position: absolute;
      max-height: 50px !important;
      top: 10px;
      left: 10px;
      width: 200px;
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
    width: 70px;

    .menu-button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .usermenu-container {
    display: flex;
    align-items: center;

    .usermenu-text {
      font-size: 16px;
      font-family: $font-family;
      font-weight: $font-weight-bigger;
      letter-spacing: -0.3px;
      line-height: 1.5;
      color: white;
      margin-left: 5px;
    }
  }
}

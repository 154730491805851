.not-found {
  width: 100%;
  height: 100%;
  background-color: $monitor-background-color;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  flex: 1;
  font-family: $font-family;
  color: white;
  font-size: 60px;

  a {
    text-decoration: none;
  }

  .to-create-view {
    margin-top: 80px;
    display: flex;
    width: 600px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
    height: 120px;
    font-size: 40px;
    background: #fff;
    border: 1px solid #888;
    box-sizing: border-box;
    border-radius: 40px;
    color: $monitor-background-color;
  }
}

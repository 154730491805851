.stopcards {
  margin-block: 0 0;
}

.stop-card-list-container {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  width: 780px;
  margin-left: 60px;
}

.buttons {
  display: flex;
  padding: 20px 15px 40px 10px;
  justify-content: center;

  .wide {
    flex: 1;
  }
}

.buttons-with-alert {
  display: flex;
  padding: 20px 15px 10px 10px;
  justify-content: center;

  .wide {
    flex: 1;
  }
}

.button {
  display: flex;
  margin: 0 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 28px;
  height: 40px;
  background: #fff;
  border: 1px solid #888;
  box-sizing: border-box;
  border-radius: 40px;
  cursor: pointer;

  span {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: $primary-color;
    white-space: nowrap;
  }

  &.blue {
    background: $primary-color;
    margin-left: 5px;

    span {
      color: #fff;
    }
  }

  &:disabled {
    cursor: unset;
    background: #ccc;
    border: 1px solid #ccc;
    color: #fff;

    span {
      color: #fff;
    }
  }
}

.cardlist-alert-text {
  text-align: left;
  color: $alert-color;
  font-size: 12px;
  margin: 8px;
  font-weight: bold;
}

.display-settings-container {
  display: flex;
  justify-content: space-between;
  font-family: $font-family;
  margin-top: 26px;
  height: 100px;

  .headers {
    display: flex;
    margin-bottom: 10px;
    margin-left: 5px;
  }
}

.map-toggle {
  padding: 0 10px;
  height: 44px;
  display: flex;
  border-radius: 5px;
  background-color: white;
  align-items: center;

  .toggle {
    margin: 0;
  }

  .txt {
    display: flex;
  }

  label {
    svg {
      margin-right: 5px !important;
    }
  }

  div {
    margin-right: 10px;
  }

  .option-checkbox-container {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
}

.display-language-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .language-alert {
    position: absolute;
    color: #dc0451;
    left: 19px;
    top: 5.6em;
    font-size: 0.813rem;
  }

  .language-controls {
    padding: 0 10px;
    height: 44px;
    display: flex;
    border-radius: 5px;
    background-color: white;
    align-items: center;

    label {
      svg {
        margin-right: 5px !important;
      }
    }

    div {
      margin-right: 10px;
    }

    .option-checkbox-container {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }
}

.display-orientation-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;

  .orientation-controls {
    height: 44px;
    display: flex;
    width: 140px;

    .orientation-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: none;
      background-color: white;
      border-radius: 0 5px 5px 0;
      fill: $primary-color;

      div {
        margin-left: 8px;
      }
    }

    .orientation-button:first-child {
      fill: $primary-color;
      border-right: 1px solid #ddd;
      border-radius: 5px 0 0 5px;
    }

    .orientation-button:not(.selected) {
      fill: #c4c4c4;
      cursor: pointer;
    }
  }
}

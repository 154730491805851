.monitor-controls {
  height: 80px;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: flex-end;

  .monitor-button {
    height: 40px;
    font-size: 16px;
    margin: 0;
  }
}

.stopcard-row-container {
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 720px;
  height: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  margin-bottom: 10px;

  .title-with-icons {
    display: flex;
    height: 80px;
    width: 720px;
    border-bottom: 1px solid #ddd;

    .title-list {
      flex: 1;
      display: flex;
    }

    .icons {
      display: flex;
      height: 40px;
      position: relative;
      top: 20px;
      margin-left: auto;

      .delete {
        cursor: pointer;
        width: 50px;
        padding-top: 8px;
        text-align: center;

        &.move-end {
          text-align: right;
          position: relative;
          left: 36px;
        }
      }

      .move {
        position: relative;
        right: 3px;
        cursor: pointer;
        border-left: 1px solid #ddd;
        width: 50px;
        text-align: center;

        &.up-and-down {
          .container {
            width: 50px;
            height: 40px;
          }

          .move-divider {
            display: flex;
            justify-content: center;

            div {
              border-top: 1px solid #ddd;
              width: 24px;
            }
          }

          .move-down {
            margin-top: 3px;
          }
        }
      }
    }
  }

  .header {
    position: relative;
    margin-top: 16px;
    font-style: normal;
    font-weight: $font-weight;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #666;
  }

  .headers {
    font-weight: $font-weight-bigger;
    display: flex;
    margin-top: 22px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;

    .stop {
      width: 440px;
    }

    .layout {
      width: 130px;
    }

    .duration {
      margin-left: 20px;
      white-space: nowrap;
    }
  }

  .search-stop-with-layout-and-time {
    display: flex;
    margin-top: 5px;

    .search-stop {
      width: 380px;
      margin-right: 40px;

      div[class^='styles_autosuggest-input-icon'] svg {
        fill: #888;
      }

      position: relative;

      .add-stop-alert {
        color: #dc0451;
        font-size: 0.813rem;
        position: absolute;
        top: -20px;
        right: 0;
      }

      input[class^='styles_input'] {
        border: 1px solid #888;
      }
    }
  }

  .double-inputs {
    display: flex;

    .stop-list-title-input {
      width: 100%;
      max-width: 300px;

      .header {
        padding-bottom: 16px;
        margin-left: 2px;
      }

      .monitor-input-container {
        width: unset;
        display: flex;
        margin-right: 10px;

        .monitor-input {
          font-size: 18px;
          width: 100%;

          &:read-only {
            outline: none;
            border: none;
          }
        }
      }
    }
  }

  .stop-list {
    position: relative;
    list-style-type: none;
  }
}

input {
  font-family: $font-family;
}

.user-view-title {
  display: flex;
  flex-direction: row;
  background: #f2f5f7;
  color: #666;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 18px;

  .user-view-title-input-container {
    display: flex;
    flex: 1;

    .monitor-input-container {
      max-width: 300px;

      button {
        background-color: #f2f5f7;
      }
    }

    .monitor-input {
      font-size: 26px;
      background: #f2f5f7;

      &:focus {
        outline: auto $primary-color;
      }
    }

    .user-view-title-input-button {
      display: flex;
      align-items: center;
    }
  }

  .delete-icon {
    cursor: pointer;
    padding: 0 10px;
    background-color: #f2f5f7;
  }
}

#root {
  font-family: var(--font-family);
  height: 100%;
}

$font-family: var(--font-family);
$font-weight: var(--font-weight);
$font-weight-bigger: var(--font-weight-bigger);
$alert-color: var(--alert-color);
$primary-color: var(--primary-color);
$monitor-background-color: var(--monitor-background-color);
$monitor-font: var(--monitor-font);
$monitor-font-weight: var(--monitor-font-weight);
$monitor-font-weight-bigger: var(--font-weight-bigger);
$monitor-button-background-color: var(--monitor-button-background-color);

ul,
li {
  list-style-type: none;
}

/* stylelint-disable selector-class-pattern */
.ReactModal__Content {
  font-family: var(--font-family);
  padding: 0 !important;
}

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgb(0 0 0 / 40%) !important;
}

.animate-in {
  animation-duration: 0.5s;
  animation-name: animate-in;
  animation-delay: var(--delay-length);
  animation-fill-mode: backwards;
}

@keyframes animate-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.sr-only:not(:focus, :active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

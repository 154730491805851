.stop-title {
  background: #ffff;
  color: #666;
  height: 80px;
  width: 100%;
  max-width: 300px;
}

.stop-title-input-container {
  margin-right: 10px;

  .monitor-input.double {
    font-size: 21px;
  }

  .monitor-input-container {
    width: unset;
    display: flex;

    .monitor-input {
      font-size: 21px;
      font-style: normal;
      font-weight: $font-weight;
      line-height: 24px;
      letter-spacing: -0.3px;
      text-align: left;
      height: 24px;
      flex: 1;
      border: none;
      width: 100%;
    }
  }

  &:focus {
    outline: auto $primary-color;
  }
}

.description {
  font-size: 13px;
  font-style: normal;
  font-weight: $font-weight;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  white-space: nowrap;
  margin: 17px 0 0 3px;
}

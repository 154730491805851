@import '../index';

.layout-modal {
  .monitor-modal-container {
    margin: 0 80px;

    .monitor-modal-header {
      margin-bottom: 0;
    }
  }

  .layout-modal-content-container {
    $primary-color: var(--primary-color);

    font-family: var(--font-family-normal);
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    .row-header {
      font-weight: $font-weight;
      margin: 28px 0 10px;
      font-size: 1.125rem;
    }

    .info-display-warning {
      font-size: 14px;
    }

    .row-info {
      margin-bottom: 10px;
    }
  }

  .layouts {
    .row {
      .header {
        padding-bottom: 20px;
        display: flex;
        align-items: flex-end;
        flex-flow: column;
      }

      .options {
        display: flex;

        .option:disabled::after {
          position: absolute;
          content: '';
          height: 100%;
          width: 100%;
          background-color: rgb(221 221 221 / 70%);
        }

        .option {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 0;

          svg {
            position: relative;
            top: 10px;
          }

          &.vertical {
            height: 210px;
            width: 121px;
            margin-right: 20px;
          }

          &.label-selected {
            border: 1px solid transparent;
            box-shadow: 0 0 0 2px #333;
          }

          height: 140px;
          width: 174px;
          margin-right: 20px;
          background: transparent;

          .label {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            margin-top: 20px;
            margin-bottom: 10px;
            width: 100%;
          }
        }

        .option:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    .close-button {
      font-size: 1.125em;
      font-style: normal;
      font-weight: $font-weight;
      line-height: 22px;
      letter-spacing: -0.02em;
      text-align: center;
      background: $primary-color;
      color: #fff;
      border-radius: 50px;
      width: 216px;
      height: 50px;
      margin: 30px 0 50px;
      border: none;
    }
  }
}

.menu-content {
  display: flex;
  width: 400px;
  position: fixed;
  background-color: #fff;
  left: auto;
  right: 0;
  height: 100% !important;
  border-radius: 0 !important;
  transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
  transform: translate(100%, 0);

  .container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    width: 100%;

    #close {
      display: flex;
      justify-content: flex-end;

      .close-button {
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }

    #languages {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #ddd;

      .lang-select {
        color: $primary-color;
        font-family: $font-family;
        font-weight: $font-weight-bigger;
        padding: 10px;
        text-decoration: none;
        line-height: normal;
        font-size: 1.25rem;
        display: inline-block;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    #links {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ddd;
      padding: 15px 0;

      .link {
        color: $primary-color;
        font-family: $font-family;
        font-weight: $font-weight-bigger;
        line-height: 35px;
        letter-spacing: 0;
        padding: 0.5em 0;
        text-decoration: none;
        width: 100%;
        background: #fff;
        overflow-y: auto;
        text-align: left;
      }
    }
  }
}

.menu-content-close {
  transform: translate(100%, 0) !important;
  opacity: 1 !important;
}

.menu-content-open {
  transform: translate(0);
}

.menu-background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1200;
  background-color: rgb(0 0 0 / 40%);
  transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.menu-background-close {
  opacity: 0 !important;
}

.menu-background-open {
  opacity: 1;
}

.loading-container {
  width: 100%;
  height: 100%;
  background-color: $monitor-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &.primary {
    background-color: $primary-color;
  }

  &.small {
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  &.white {
    background-color: #fff;

    svg {
      height: 100000px;
      stroke: #ddd !important;
    }
  }

  svg {
    width: 60px !important;
    height: 60px !important;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.layout-and-time-container {
  display: flex;
  width: 280px;
  justify-content: flex-end;

  .layout-button {
    background-color: #fff;
    border: 1px solid  hsl(0deg 0% 70%);
    border-radius: 5px;
    height: 44px;
    width: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      height: 36px !important;
      width: 64px !important;
      margin-left: 3px !important;
    }

    .label {
      margin-left: 10px;
      font-size: 15px;
      color: #888;
    }
  }

  .duration {
    min-width: 130px;
  }
}

.breadcrumbs-container {
  font-family: $font-family;
  font-weight: $font-weight-bigger;
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  padding-top: 39px;

  .crumbs {
    .to-home {
      text-decoration: none;
      color: $primary-color;
    }

    display: flex;
    flex-direction: row;

    svg {
      margin-right: 10px;
      margin-left: 10px;
      position: relative;
      top: 2px;
    }
  }

  .main-header {
    padding-top: 53px;
    font-size: 36px;
    font-style: normal;
    font-weight: $font-weight-bigger;
    line-height: 44px;
    letter-spacing: -0.03em;
    text-align: left;
  }
}

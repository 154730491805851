body,
html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
}

ul {
  padding-inline-start: 0;
}

.App { /* stylelint-disable-line */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btn {
  font-size: 1.125em;
  font-style: normal;
  font-weight: $font-weight;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: center;
  background: $primary-color;
  border-radius: 50px;
  width: 216px;
  height: 50px;
  color: #fff;
  cursor: pointer;
}

button {
  font-family: $font-family;
  white-space: nowrap;
  background-color: white;
  padding: 0;
  margin: 0;
  border: none;
}

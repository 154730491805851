
.monitor-input-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  .monitor-input {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.3px;
    flex: 1;
    border: none;

    &:focus {
      outline: auto $primary-color;
    }
  }

  svg {
    cursor: pointer;
    display: flex;
    place-content: center center;
  }
}

.monitor-input:read-only {
  border: none;
  outline: none;
}

.option-toggle-container {
  align-self: center;

  label {
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 3px;

    &.checked {
      border: $primary-color 1px solid;

      &::before {
        /* content: "#{'\2714 \fe0e'}"; */
        color: var(--font-family);
        font-family: $font-family;
        font-size: 1.25em;
        font-weight: var(--font-weight);
        line-height: 1;
        margin-left: 0.1em;
        margin-top: 0.2em;
        transform: rotateY(15deg);
      }
    }
  }

  > span {
    font-weight: var(--font-weight);
    user-select: none;
    margin-top: 0.75em;
  }

  /* Box around the slider */
  .toggle {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 31px;
  }

  /* Hide default HTML checkbox */
  .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: #ccc;
    transition: 0.4s;
  }

  .slider::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 0;
    bottom: 0;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $primary-color;
  }

  input:focus-visible + .slider {
    box-shadow: 0 0 0 2px black;
  }

  input:checked + .slider::before {
    transform: translateX(20px);
    border: none;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    background: white;
    border: 1px solid #888;

    &.disabled {
      background: grey !important;
    }
  }

  .slider.round::before {
    border-radius: 50%;
    width: 29px;
    height: 29px;
    border: solid 0.5px #888;
    background-color: #fff;
  }
}
